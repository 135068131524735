import React, { ReactElement } from 'react';
import { motion } from 'framer-motion';
import { CardMedia, Container, Grid, Link, List, ListItem, Stack, Typography } from '@mui/material';
import RoundedButton from '@components/Buttons/RoundedButton';

interface SectionFeatureProps {
    title?: string;
    description?: string;
    description2?: string;
    descriptionList?: string[];
    descriptionImage?: string;
    image: string;
    button?: string;
    link?: string;
    mirror?: boolean;
}
const SectionFeature: React.FunctionComponent<SectionFeatureProps> = (props) => {
    const { title, description, description2, descriptionList, descriptionImage, image, button, link, mirror } = props;

    const getImage = () => {
        return <CardMedia sx={{ borderRadius: 10 }} component="img" image={image} alt="sectionFeatureImage" />;
    };

    const getDescription = () => {
        return (
            <Stack alignItems="center" textAlign="center" spacing={2}>
                {title && (
                    <Typography
                        sx={{
                            fontSize: { sm: 20, md: 25 }
                        }}
                        fontWeight={800}
                    >
                        {title}
                    </Typography>
                )}
                {description && (
                    <Typography
                        sx={{
                            fontSize: { sm: 16, md: 18 }
                        }}
                    >
                        {description}
                    </Typography>
                )}
                {description2 && (
                    <Typography
                        sx={{
                            fontSize: { sm: 16, md: 18 }
                        }}
                    >
                        {description2}
                    </Typography>
                )}
                {descriptionList && descriptionList.length > 0 && (
                    <List
                        sx={{
                            fontSize: { xs: 14, sm: 16, md: 18 },
                            listStyleType: 'disc',
                            listStylePosition: 'inside'
                        }}
                    >
                        {descriptionList.map((list) => (
                            <ListItem sx={{ display: 'list-item' }}>{list}</ListItem>
                        ))}
                    </List>
                )}
                {descriptionImage && (
                    <CardMedia
                        sx={{ borderRadius: 10 }}
                        component="img"
                        image={descriptionImage}
                        alt="sectionFeatureImage"
                    />
                )}
                {button && (
                    <Link href={link && link} target="_blank" style={{ textDecoration: 'none' }}>
                        <RoundedButton>{button}</RoundedButton>
                    </Link>
                )}
            </Stack>
        );
    };

    return (
        <Container>
            <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{
                    duration: 1.5,
                    type: 'spring',
                    bounce: 0.5
                }}
                viewport={{
                    amount: 0.5
                }}
            >
                <Grid container alignItems="center" spacing={8}>
                    <Grid item xs={12} md={6}>
                        {mirror ? getDescription() : getImage()}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {mirror ? getImage() : getDescription()}
                    </Grid>
                </Grid>
            </motion.div>
        </Container>
    );
};

export default SectionFeature;
