import React from 'react';
import Home from '@screens/Home';
import Facilities from '@screens/Facilities';
import Pricing from '@screens/Pricing';
import Contact from '@screens/Contact';

export interface RoutesInterface {
    path: string;
    name: string;
    element: React.FunctionComponent;
}

const appRoutes: RoutesInterface[] = [
    {
        path: '/',
        name: 'Home',
        element: Home
    },
    {
        path: '/facilities',
        name: 'Facilities',
        element: Facilities
    },
    {
        path: '/pricing',
        name: 'Pricing',
        element: Pricing
    },
    {
        path: '/contact',
        name: 'Contact',
        element: Contact
    }
];

export default appRoutes;
