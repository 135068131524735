import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#F79D5C'
        },
        secondary: {
            main: '#242128'
        }
    },
    typography: {
        fontSize: 14,
        button: {
            borderRadius: 15,
            textTransform: 'none'
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    borderRadius: 10,
                    fontSize: 18,
                    padding: '10px 30px 10px 30px'
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    borderRadius: 5,
                    fontSize: 18,
                    ...(ownerState.color === 'secondary' && {
                        backgroundColor: '#242128',
                        color: '#fff',
                        '&:hover': {
                            backgroundColor: '#515151'
                        }
                    }),
                    ...(ownerState.color === 'info' && {
                        backgroundColor: 'white',
                        color: 'black',
                        '&:hover': {
                            backgroundColor: '#D4D4D4'
                        }
                    })
                })
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 10
                }
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    borderRadius: 15,
                    padding: 12,
                    '&:before': {
                        display: 'none'
                    }
                }
            }
        }
    }
});

export default theme;
