export const Links = {
    JobApplicationLink:
        'https://docs.google.com/forms/d/1jniZOPTvJfMPQrKAQWFmmKJlLb_NYPYxGvkyvGSpy1A/viewform?edit_requested=true',
    ReserveLink: 'https://www.campspot.com/book/suncreekpointrv',
    SeasonalInquiry:
        'https://docs.google.com/forms/d/1pFGvAJjDRx-uuVki7BPn1iAPxvACTKGzl55Beffk15k/viewform?edit_requested=true',
    DirectionsLink:
        'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2885.838478265279!2d-116.6562703231393!3d43.672328971101216!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54afb30879384063%3A0x13ba42a84ed87b38!2s213%20Aviation%20Way%2C%20Caldwell%2C%20ID%2083605%2C%20EE.%20UU.!5e0!3m2!1sen!2sus!4v1718935793663!5m2!1sen!2sus',
    InformationLink: 'https://pointrv.com/sun-creek-point-location/',
    AddressLink: 'https://maps.app.goo.gl/MKFLD6myTaVouvoQ6',
    PhoneLink: 'tel:2084410411',
    EmailLink: 'mailto:suncreekpointrvpark@gmail.com',
    ParkGuidelinePDF: '/pdf/park-guideliness.pdf'
};
