import React from 'react';
import { Box, Container, Typography, Grid, Link, CardMedia, Stack } from '@mui/material';
import { COLORS } from '@colors/index';
import RoundedButton from '@components/Buttons/RoundedButton';
import { Links } from '@links/index';
import { motion } from 'framer-motion';
import SectionFeature from '@components/SectionFeature';
const Facilities: React.FunctionComponent = () => {
    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
            <Box>
                <Grid
                    container
                    alignItems="center"
                    sx={{
                        height: '50vh',
                        width: '100%'
                    }}
                >
                    <Box
                        sx={{
                            backgroundImage: 'url(/img/backgrounds/facilitiesBackground.jpg)',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            position: 'absolute',
                            height: '50vh',
                            width: '100vw',
                            filter: 'brightness(30%)',
                            zIndex: -1
                        }}
                    />
                    <Container>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Typography
                                    fontWeight={800}
                                    color={COLORS.White}
                                    textAlign="center"
                                    sx={{ fontSize: { xs: 40, sm: 50, md: 60 } }}
                                >
                                    Facilities
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{ fontSize: { xs: 20, sm: 30, md: 40 } }}
                                    fontWeight={800}
                                    color={COLORS.White}
                                    textAlign="center"
                                >
                                    Convenient Location. Luxurious Amenities.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <Link href={Links.ReserveLink} target="_blank" style={{ textDecoration: 'none' }}>
                                    <RoundedButton variant="contained">Reserve</RoundedButton>
                                </Link>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
                <Grid container my={12}>
                    <SectionFeature
                        title="Luxurious Clubhouse"
                        description=" Our two clubhouses provide ample room to relax, gather, and experience many
                                        activities. The first clubhouse is a luxurious 11,000 square foot clubhouse
                                        equipped with laundry facilities, a pool, three hot tubs, golf simulator, full
                                        size sports court, game rooms, private showers and bathrooms. Built for
                                        everyone, this park has massive open areas for outdoor gatherings, games, and
                                        relaxation. The park also includes the following. Our other clubhouse is a
                                        dedicated area for our 18+ section of the park."
                        image="/img/facilities/img1.jpg"
                    />
                </Grid>
                <Grid container my={12}>
                    <SectionFeature image="/img/facilities/img2.jpg" descriptionImage="/img/facilities/img3.jpg" />
                </Grid>
                <Grid container my={12}>
                    <SectionFeature
                        title=" Park Amenities"
                        description="You can choose whether you prefer patio sites, private yard sites, pull
                                        throughs, or even a 18+ section of the park. Sun Creek offers a luxurious resort
                                        experience."
                        image="/img/facilities/img4.jpg"
                    />
                </Grid>
            </Box>
        </motion.div>
    );
};
export default Facilities;
