import React, { useState } from 'react';
import { Box, CssBaseline } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import Navbar from '@components/Navbar';
import CustomDrawer from '@components/CustomDrawer';
import Footer from '@components/Footer';

const LandingPage: React.FunctionComponent = (props: any) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const location = useLocation();

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <Box>
            <CssBaseline />
            {location.pathname === '/' ? (
                <Navbar handleDrawerToggle={handleDrawerToggle} />
            ) : (
                <Navbar fixContent handleDrawerToggle={handleDrawerToggle} />
            )}
            <CustomDrawer drawerOpen={drawerOpen} handleDrawerToggle={handleDrawerToggle} />
            <Outlet />
            <Footer />
        </Box>
    );
};
export default LandingPage;
