import React from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import { COLORS } from '@colors/index';
import { Links } from '@links/index';

const Contact: React.FunctionComponent = () => {
    return (
        <Box py={12}>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} textAlign="center">
                        <Box
                            component="iframe"
                            src={Links.DirectionsLink}
                            sx={{
                                width: { xs: '100%', sm: 500 },
                                height: { xs: 350, sm: 500 },
                                border: 0
                            }}
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            allowFullScreen
                        />
                        <Typography variant="h4" fontWeight={500} gutterBottom>
                            Sun Creek Point RV Park
                        </Typography>
                        <Typography variant="body1">
                            {'Address: '}
                            <Link color={COLORS.Primary} href={Links.AddressLink} target="_blank">
                                213 Aviation Way North, Caldwell, ID
                            </Link>
                        </Typography>
                        <Typography variant="body1">
                            {'Phone: '}
                            <Link color={COLORS.Primary} href={Links.PhoneLink}>
                                (208) 441-0411
                            </Link>
                        </Typography>
                        <Typography variant="body1">
                            {'Email: '}
                            <Link color={COLORS.Primary} href={Links.EmailLink}>
                                suncreekpointrvpark@gmail.com
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
export default Contact;
